var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header-view',{attrs:{"title":"Edit Work Crew","previous_page":'/crews/view/' + _vm.crew.uuid,"btnOptions":[
      {
        name: 'Save',
        action: this.handleSubmit,
      },
      {
        name: '',
        icon: 'mdi-delete',
        action: function () {
          _vm.deleteCrewConfirm = true;
        },
      } ]}}),_c('v-form',{ref:"form",staticClass:"ma-3",attrs:{"id":"crewForm"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('v-container',[_c('v-layout',{attrs:{"align-start":"","justify-start":"","row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"id":"name","label":"Name","rules":_vm.validate_name},model:{value:(_vm.crew.name),callback:function ($$v) {_vm.$set(_vm.crew, "name", $$v)},expression:"crew.name"}}),_c('v-subheader',[_vm._v("Foreman")]),_c('v-select',{attrs:{"items":_vm.usersSelectOptions,"label":"Assign Foreman","solo":""},model:{value:(_vm.selectedForeman),callback:function ($$v) {_vm.selectedForeman=$$v},expression:"selectedForeman"}}),_c('v-select',{attrs:{"id":"status","items":_vm.possible_status,"label":"Status","rules":_vm.validate_status},model:{value:(_vm.crew.status),callback:function ($$v) {_vm.$set(_vm.crew, "status", $$v)},expression:"crew.status"}})],1)],1),(_vm.errorMessage !== '')?_c('div',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" "),_c('v-btn',{on:{"click":function($event){_vm.errorMessage = ''}}},[_vm._v(" OK ")])],1):_vm._e()],1)],1),_c('simple-dialog-template',{attrs:{"open":_vm.deleteCrewConfirm,"dialogTitle":"Delete Crew Confirmation","dialogText":"Are you super sure that you want to delete this crew?","dialogButtonOne":"Delete","dialogButtonTwo":"Cancel"},on:{"buttonOne":_vm.deleteCrew,"buttonTwo":function($event){_vm.deleteCrewConfirm = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }