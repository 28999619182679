<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP CREW EDIT

type    : view

uses    : header-view
          simple-dialog-template

route   : /crews/edit/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div>
    <header-view
      title="Edit Work Crew"
      :previous_page="'/crews/view/' + crew.uuid"
      :btnOptions="[
        {
          name: 'Save',
          action: this.handleSubmit,
        },
        {
          name: '',
          icon: 'mdi-delete',
          action: () => {
            deleteCrewConfirm = true;
          },
        },
      ]" />
    <v-form
      ref="form"
      @submit.prevent="handleSubmit"
      id="crewForm"
      class="ma-3">
      <v-container>
        <v-layout align-start justify-start row>
          <v-flex xs12>
            <v-text-field
              id="name"
              v-model="crew.name"
              label="Name"
              :rules="validate_name" />

            <v-subheader>Foreman</v-subheader>
            <v-select
              :items="usersSelectOptions"
              label="Assign Foreman"
              v-model="selectedForeman"
              solo></v-select>

            <v-select
              id="status"
              v-model="crew.status"
              :items="possible_status"
              label="Status"
              :rules="validate_status" />
          </v-flex>
        </v-layout>

        <div v-if="errorMessage !== ''" class="headline">
          {{ errorMessage }}
          <v-btn @click="errorMessage = ''"> OK </v-btn>
        </div>
      </v-container>
    </v-form>
    <simple-dialog-template
      :open="deleteCrewConfirm"
      dialogTitle="Delete Crew Confirmation"
      dialogText="Are you super sure that you want to delete this crew?"
      dialogButtonOne="Delete"
      dialogButtonTwo="Cancel"
      @buttonOne="deleteCrew"
      @buttonTwo="deleteCrewConfirm = false" />
  </div>
</template>

<script>
  import axios from 'axios';

  import SimpleDialogTemplate from '@/components/simple-dialog-template';
  import HeaderView from '@/components/header-view';

  export default {
    name: 'CrewCreate',
    components: {
      'simple-dialog-template': SimpleDialogTemplate,
      'header-view': HeaderView,
    },
    props: {
      uuid: {
        default: '',
        type: String,
      },
    },
    data() {
      return {
        possible_status: ['Active', 'Inactive'],
        errorMessage: '',
        loader: false,
        loading4: false,
        crew: {},
        usersSelectOptions: [],
        submitted: false,
        selectedForeman: '',
        deleteCrewConfirm: false,
        validate_name: [(v) => !!v || 'This is required'],
        validate_status: [(v) => !!v || 'This is required'],
      };
    },
    watch: {
      loader() {
        const l = this.loader;
        this[l] = !this[l];

        setTimeout(() => (this[l] = false), 3000);

        this.loader = null;
      },
    },
    async mounted() {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      await axios({
        method: 'GET',
        url: '/service_tenants/crews/all',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((results) => {
          this.crew = results.data[0];
          this.selectedForeman = this.crew.foreman_uuid;
          // console.log( "heres the crew and foreman" )
          // console.log( this.crew )
          // console.log( this.selectedForeman )
        })
        .catch((error) => {
          console.log('Error: ', error);
        });

      let params = {};
      params['tenant_uuid'] = this.$auth.userProfile.tenant_uuid;
      this.busy = true;
      const url = '/service_users/users/all';
      axios({
        method: 'GET',
        url: url,
        params: params,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (response) {
            // console.log( "heres the response data" )
            // console.log( response.data )
            this.usersSelectOptions = response.data.map((x) => {
              return {
                text: x.name,
                value: x.uuid,
              };
            });

            // console.log( "heres the select options" )
            // console.log( this.usersSelectOptions )

            this.busy = false;
          }
        })
        .catch((error) => {
          if (error.hasOwnProperty('response')) {
            if (error.response.hasOwnProperty('status')) {
              //use the http status to set a custom error message (with internationalization!)
              this.errorMessage = 'API call failed';
            }
          }
          this.busy = false;
        });
    },
    methods: {
      async handleSubmit() {
        // console.log( "edit form submitted" )
        // console.log( this.crew )
        if (!this.$refs.form.validate()) {
          return false;
        }
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();
        this.crew.foreman_uuid = this.selectedForeman;
        axios({
          method: 'PUT',
          url: '/service_tenants/crews/' + this.crew.uuid,
          data: this.crew,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((result) => {
            if (!result.data.error) {
              this.$router.push('/crews/view/' + result.data.uuid);
            } else {
              this.errorMessage = result.data.error.original.detail;
              // TODO: fix this use of $nextTick. It's probably a mistake
              // since it doesn't have a callback.
              // eslint-disable-next-line vue/valid-next-tick
              this.$nextTick();
            }
          })
          .catch((error) => {
            console.log('Error: ', error);
          });
      },
      async deleteCrew() {
        // close dialog
        this.deleteCrewConfirm = false;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();
        axios({
          method: 'DELETE',
          url: '/service_tenants/crews/' + this.crew.uuid,
          data: this.crew,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
          .then((result) => {
            if (!result.data.error) {
              this.$router.push('/crews');
            } else {
              this.errorMessage = result.data.error.original.detail;
              // TODO: fix this use of $nextTick. It's probably a mistake
              // since it doesn't have a callback.
              // eslint-disable-next-line vue/valid-next-tick
              this.$nextTick();
            }
          })
          .catch((error) => {
            console.log('Error: ', error);
          });
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }

  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
